var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('AddEditRentStationArea'),(_vm.rent_station_area != null)?_c('div',{staticClass:"flex flex-wrap"},[_c('div',{class:_vm.is_full ? 'w-0' : 'w-1/2 px-6 py-4 bg-white md-max:w-full'},[_c('div',{staticClass:"flex flex-wrap items-center"},[_c('div',{staticClass:"flex items-center w-2/3 pl-2 md-max:w-full"},[_c('div',{staticClass:"items-center justify-center mr-4 cursor-pointer back-button",on:{"click":_vm.goBack}},[_c('i',{staticClass:"fas fa-arrow-left"})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.titleText))]),_c('h3',{staticClass:"pl-1 font-semibold text-gray-900 text-22px"},[_vm._v(" "+_vm._s(_vm.$t( 'components.rentStationAreaManagement.details.headline.details' ))+" (#"+_vm._s(_vm._f("onlyLastFive")(_vm.rent_station_area.id))+") ")])]),_c('div',{staticClass:"flex items-center justify-end w-1/3 pl-2 md-max:w-full md-max:pt-3"},[_c('oto-edit-button',{on:{"click":_vm.edit}})],1)]),_c('div',{staticClass:"flex flex-col mt-4 mb-10 space-x-0 space-y-4 md:space-x-4 md:space-y-0 md:items-center md:flex-row"},[_c('SummaryCard',{attrs:{"title":_vm.$t(
              'components.rentStationAreaManagement.details.summary.totalAssignedVehicles'
            ),"value":_vm.rent_station_area.total_assigned_vehicles,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t(
              'components.rentStationAreaManagement.details.summary.totalRentals'
            ),"value":_vm.rent_station_area.total_rentals,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t(
              'components.rentStationAreaManagement.details.summary.totalOngoingRentals'
            ),"value":_vm.rent_station_area.total_ongoing_rentals,"variant":"gray"}})],1),_c('div',{staticClass:"flex items-center my-12"},[_c('div',{staticClass:"block w-1/2 pl-2"},[_c('span',{staticClass:"block detail-title"},[_vm._v(_vm._s(_vm.$t( 'components.rentStationAreaManagement.details.rentStationAreaName' )))]),_c('span',{staticClass:"block mt-4 detail-value"},[_vm._v(_vm._s(_vm.rent_station_area.name))])]),_c('div',{staticClass:"block w-1/2 pl-2"},[_c('span',{staticClass:"block detail-title"},[_vm._v(_vm._s(_vm.$t('components.rentStationAreaManagement.details.serviceAreaName')))]),_c('span',{staticClass:"block mt-4 detail-value"},[_vm._v(_vm._s(_vm.rent_station_area.geofence ? _vm.rent_station_area.geofence.name : '--'))])])]),_c('div',{staticClass:"flex items-center my-12"},[_c('div',{staticClass:"block w-1/2 pl-2"},[_c('span',{staticClass:"block detail-title"},[_vm._v(_vm._s(_vm.$t('components.rentStationAreaManagement.details.address')))]),_c('span',{staticClass:"block mt-4 detail-value"},[_vm._v(_vm._s(_vm.rent_station_area.address ? _vm.rent_station_area.address : ''))])]),_c('div',{staticClass:"block w-1/2 pl-2"},[_c('span',{staticClass:"block detail-title"},[_vm._v(_vm._s(_vm.$t('components.rentStationAreaManagement.details.rentStationNote')))]),_c('span',{staticClass:"block mt-4 detail-value"},[_vm._v(_vm._s(_vm.rent_station_area.description ? _vm.rent_station_area.description : ''))])])])]),_c('div',{class:_vm.is_full
          ? 'w-full relative'
          : 'w-1/2 relative md-max:w-full md-max:px-0'},[_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"100vh"},attrs:{"zoom":_vm.zoom,"center":_vm.center,"map-type-id":"terrain"},on:{"click":_vm.toggleMap,"zoom_changed":_vm.getCurrentZoom}},[_c('gmap-marker',{key:"current",attrs:{"position":_vm.getLatLng(_vm.rent_station_area.center_coords),"icon":{
            url: require("@/assets/img/areas_pin/Rent_R_icon.png"),
            scaledSize: { width: 55, height: 65 },
          }},on:{"mouseover":function($event){return _vm.toggleMarker(_vm.rent_station_area, -1)},"click":function($event){return _vm.moveCamera(_vm.rent_station_area)}}}),_vm._l((_vm.rent_station_area.other_areas_in_geofence),function(area,index){return _c('gmap-marker',{key:index,attrs:{"position":_vm.getLatLng(area.center_coords),"icon":{
            url: require("@/assets/img/areas_pin/Rent_R_icon.png"),
            scaledSize: { width: 55, height: 65 },
          }},on:{"mouseover":function($event){return _vm.toggleMarker(area, index)},"click":function($event){return _vm.moveCamera(area)}}})}),_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){_vm.infoWinOpen = false}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.infoContent)}})])],2),_c('div',{staticClass:"area-info"},[_c('div',{staticClass:"flex flex-wrap items-center justify-between px-4  py-2 area-body"},_vm._l((_vm.bottom_tabs),function(item,index){return _c('round-tab',{key:index,attrs:{"type":item.type,"name":item.name},on:{"changeArea":_vm.changeArea}})}),1)])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }