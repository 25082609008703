var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('slide-over-right',{attrs:{"id":_vm.sorId,"full-width":true}},[_c('loading',{attrs:{"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-1/2 md-max:w-full"},[_c('div',{staticClass:"flex items-center pl-8 mt-6 md-max:pb-6"},[_c('div',{staticClass:"items-center justify-center cursor-pointer back-button",on:{"click":_vm.goBack}},[_c('i',{staticClass:"fas fa-arrow-left"})]),_c('h2',{staticClass:"ml-3 font-semibold text-gray-900 text-22px"},[_vm._v(" "+_vm._s(_vm.titleText)+" ")])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirmSubmit)}}},[_c('div',{staticClass:"grid grid-cols-1 gap-2 mt-4 lg:grid-cols-2"},[_c('text-input',{attrs:{"rules":"required","name":_vm.$t(
                  'components.sunpodStationAreaManagement.addEdit.steps.details.title.sunpodStationName'
                ),"label":_vm.$t(
                  'components.sunpodStationAreaManagement.addEdit.steps.details.title.sunpodStationName'
                )},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                  'components.sunpodStationAreaManagement.addEdit.steps.details.title.assignedServiceArea'
                ),"label":_vm.$t(
                  'components.sunpodStationAreaManagement.addEdit.steps.details.title.assignedServiceArea'
                ),"rules":"required","placeholder":_vm.getServiceAreaPlaceholder,"disabled":_vm.getServiceAreaDisabledState,"text-attribute":"name","value-attribute":"id","options":_vm.service_areas},model:{value:(_vm.form.geofence),callback:function ($$v) {_vm.$set(_vm.form, "geofence", $$v)},expression:"form.geofence"}})],1),_c('div',{staticClass:"flex items-end gap-2 mt-1"},[_c('div',{staticClass:"w-full"},[_c('PlacesAutocomplete',{attrs:{"label":_vm.$t(
                    'components.sunpodStationAreaManagement.addEdit.steps.details.title.searchForLocation'
                  ),"disabled":!!!_vm.form.geofence,"placeholder":!!_vm.form.geofence === false
                    ? _vm.$t(
                        'components.sunpodStationAreaManagement.addEdit.steps.details.placeHolder.serviceAreaAtFirst'
                      )
                    : _vm.$t(
                        'components.sunpodStationAreaManagement.addEdit.steps.details.placeHolder.enterAddress'
                      )},on:{"update:place":_vm.onUpdatingPlace,"clear:place":function () { return (_vm.place = null); }}})],1),_c('UploadGeoFile',{on:{"input":_vm.addKmlLayer}})],1),_c('div',{staticClass:"grid grid-cols-1 gap-2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                  'components.sunpodStationAreaManagement.addEdit.steps.details.title.address'
                ),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                    'components.sunpodStationAreaManagement.addEdit.steps.details.title.address'
                  )}}),_c('t-textarea',{model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1),_c('div',{staticClass:"grid grid-cols-1 gap-2 "},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                  'components.sunpodStationAreaManagement.addEdit.steps.details.title.sunpodStationAreaNote'
                ),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                    'components.sunpodStationAreaManagement.addEdit.steps.details.title.sunpodStationAreaNote'
                  )}}),_c('t-textarea',{attrs:{"rules":"required"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])])]}}])}),_c('div',{staticClass:"flex items-center justify-end pr-2 mt-6"},[_c('anchor-button',{attrs:{"variant":"secondary"},on:{"click":_vm.goBack}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.cancel'))+" ")]),_c('t-button',{staticClass:"ml-3",attrs:{"type":"submit"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.$t('components.stepNavigation.save')))])],1)],1),_c('div',{staticClass:"w-1/2 md-max:w-full md-max:pt-6"},[_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"100vh"},attrs:{"zoom":_vm.zoom,"center":_vm.center,"map-type-id":"terrain"}},[_c('gmap-polygon',{attrs:{"paths":_vm.parseToOverlay(_vm.form.coords),"editable":true,"options":_vm.drawingOptions},on:{"paths_changed":function($event){return _vm.updateEdited($event)}}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }