<template>
  <base-layout>
    <SunpodStationAreasIndex />
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import SunpodStationAreasIndex from '@/views/geofence/sunpod-station-area/index.vue'

export default {
  name: 'SunpodStationIndex',
  components: {
    BaseLayout,
    SunpodStationAreasIndex,
  },
}
</script>
