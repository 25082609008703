var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('slide-over-right',{attrs:{"id":_vm.sorId,"full-width":true}},[_c('loading',{attrs:{"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full md:w-1/2"},[_c('div',{staticClass:"flex items-center pl-8 mt-6 md-max:pl-4"},[_c('div',{staticClass:"items-center justify-center cursor-pointer back-button",on:{"click":_vm.goBack}},[_c('i',{staticClass:"fas fa-arrow-left"})]),_c('h2',{staticClass:"ml-3 font-semibold text-gray-900 text-22px"},[_vm._v(" "+_vm._s(_vm.titleText)+" ")])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirmSubmit)}}},[_c('div',{staticClass:"grid grid-cols-1 gap-2 mt-1 lg:grid-cols-2 lg:flex lg:items-start "},[_c('div',{staticClass:"w-full"},[_c('text-input',{attrs:{"rules":"required","name":_vm.$t(
                    'components.slowSpeedAreaManagement.addEdit.steps.details.title.areaName'
                  ),"label":_vm.$t(
                    'components.slowSpeedAreaManagement.addEdit.steps.details.title.areaName'
                  )},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('div',{staticClass:"w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                    'components.slowSpeedAreaManagement.addEdit.steps.details.title.assignedServiceArea'
                  ),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                      'components.slowSpeedAreaManagement.addEdit.steps.details.title.assignedServiceArea'
                    )}}),_c('t-rich-select',{attrs:{"name":"options","options":_vm.service_areas,"valueAttribute":"id","textAttribute":"name","hideSearchBox":false,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.geofence),callback:function ($$v) {_vm.$set(_vm.form, "geofence", $$v)},expression:"form.geofence"}}),_c('div',{},[_c('input-error-item',{attrs:{"message":errors[0]}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"flex items-end gap-2 mt-1"},[_c('div',{staticClass:"w-full"},[_c('PlacesAutocomplete',{attrs:{"label":_vm.$t(
                    'components.slowSpeedAreaManagement.addEdit.steps.details.title.searchForLocation'
                  ),"disabled":!!!_vm.form.geofence,"placeholder":!!_vm.form.geofence === false
                    ? _vm.$t(
                        'components.slowSpeedAreaManagement.addEdit.steps.details.placeHolder.serviceAreaAtFirst'
                      )
                    : _vm.$t(
                        'components.slowSpeedAreaManagement.addEdit.steps.details.placeHolder.enterAddress'
                      )},on:{"update:place":_vm.onUpdatingPlace,"clear:place":function () { return (_vm.place = null); }}})],1),_c('UploadGeoFile',{on:{"input":_vm.addKmlLayer}})],1),_c('div',{staticClass:"grid grid-cols-1 gap-2 mt-5 "},[_c('div',{staticClass:"w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                    'components.slowSpeedAreaManagement.addEdit.steps.details.title.speedMode'
                  )},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                      'components.slowSpeedAreaManagement.addEdit.steps.details.title.speedMode'
                    )}}),_c('t-rich-select',{attrs:{"name":"options","options":_vm.getSpeedModeOptions,"placeholder":_vm.$t(
                      'components.slowSpeedAreaManagement.addEdit.steps.details.placeHolder.speedMode'
                    ),"valueAttribute":"value","textAttribute":"text","hideSearchBox":true,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.assert_speed_mode),callback:function ($$v) {_vm.$set(_vm.form, "assert_speed_mode", $$v)},expression:"form.assert_speed_mode"}}),_c('div',{},[_c('input-error-item',{attrs:{"message":errors[0]}})],1)]}}],null,true)})],1)]),_c('section',[_c('div',{staticClass:"grid grid-cols-1 gap-2 mt-1 lg:grid-cols-2"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                    'components.slowSpeedAreaManagement.addEdit.steps.details.title.vehicleEnteringBoundaryShouldThrottle'
                  ),"label":_vm.$t(
                    'components.slowSpeedAreaManagement.addEdit.steps.details.title.vehicleEnteringBoundaryShouldThrottle'
                  ),"placeholder":_vm.$t(
                    'components.slowSpeedAreaManagement.addEdit.steps.details.placeHolder.vehicleEnteringBoundaryShouldThrottle'
                  ),"value-attribute":"value","text-attribute":"text","options":[
                  { text: 'On', value: true },
                  { text: 'Off', value: false } ],"hide-search-box":""},model:{value:(_vm.form.should_throttle),callback:function ($$v) {_vm.$set(_vm.form, "should_throttle", $$v)},expression:"form.should_throttle"}}),_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                    'components.slowSpeedAreaManagement.addEdit.steps.details.title.vehicleEnteringBoundaryShouldAlarm'
                  ),"label":_vm.$t(
                    'components.slowSpeedAreaManagement.addEdit.steps.details.title.vehicleEnteringBoundaryShouldAlarm'
                  ),"placeholder":_vm.$t(
                    'components.slowSpeedAreaManagement.addEdit.steps.details.placeHolder.vehicleEnteringBoundaryShouldAlarm'
                  ),"value-attribute":"value","text-attribute":"text","options":[
                  { text: 'Trigger', value: true },
                  { text: 'Do not Trigger', value: false } ],"hide-search-box":""},model:{value:(_vm.form.should_trigger),callback:function ($$v) {_vm.$set(_vm.form, "should_trigger", $$v)},expression:"form.should_trigger"}})],1)]),_c('div',{staticClass:"grid grid-cols-1 gap-2 mt-2 lg:grid-cols-2"},[_c('div',{staticClass:"w-full "},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                    'components.slowSpeedAreaManagement.addEdit.steps.details.title.address'
                  ),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                      'components.slowSpeedAreaManagement.addEdit.steps.details.title.address'
                    )}}),_c('t-textarea',{attrs:{"rules":"required"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1),_c('div',{staticClass:"w-full"},[_c('input-label',{attrs:{"text":_vm.$t(
                    'components.slowSpeedAreaManagement.addEdit.steps.details.title.note'
                  )}}),_c('t-textarea',{attrs:{"name":"slowzoneAreaDescription"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('input-error-item')],1)]),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])])]}}])}),_c('div',{staticClass:"flex items-center justify-end pr-6 mt-6"},[_c('slide-over-right-trigger',{attrs:{"id":_vm.sorId}},[_c('anchor-button',{attrs:{"variant":"secondary"}},[_vm._v(_vm._s(_vm.$t('components.stepNavigation.cancel')))])],1),_c('t-button',{staticClass:"ml-3",attrs:{"type":"submit"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.$t('components.stepNavigation.save')))])],1)],1),_c('div',{staticClass:"w-1/2 md-max:w-full md-max:pt-6"},[_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"100vh"},attrs:{"zoom":_vm.zoom,"center":_vm.center,"map-type-id":"terrain"}},[_c('gmap-polygon',{attrs:{"paths":_vm.parseToOverlay(_vm.form.coords),"editable":true,"options":_vm.drawingOptions},on:{"paths_changed":function($event){return _vm.updateEdited($event)}}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }